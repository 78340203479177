import React, { useState, useRef } from 'react'
import { getURLParam } from '@helpers/general'
import { unsubscribeEmail } from '@services/email'
import classNames from 'classnames'
import { validateEmail } from '@helpers/string-helper'
import loaderLight from '@assets/images/loader-light.svg'
import './unsubscribe.sass'

function Unsubscribe() {
  const inputRef = useRef()
  const [messageData, setMessageData] = useState(null)
  const [loading, setLoading] = useState(false)
  const queryEmail = getURLParam('email')

  const showSuccess = successMessage =>
    setMessageData({
      status: 'success',
      message: successMessage,
    })
  const showError = errorMessage =>
    setMessageData({
      status: 'error',
      message: errorMessage,
    })

  const handleClick = async () => {
    const email = queryEmail || inputRef?.current?.value
    if (inputRef?.current) inputRef.current.value = ''
    if (email && validateEmail(email)) {
      setLoading(true)
      const response = await unsubscribeEmail(email).catch(() => {
        showError(`Unable to unsubscribe ${email}. Please contact customer support for assistance.`)
      })
      if (response && response.statusCode >= 200 && response.statusCode < 300) {
        showSuccess(`${email} successfully unsubscribed.`)
      } else {
        showError(`Unable to unsubscribe ${email}. Please contact customer support for assistance.`)
      }
    } else {
      showError('Not a valid email address format. Please re-enter.')
    }
    setLoading(false)
  }

  return (
    <div className="card">
      <div className="generated-page grid-y unsubscribe-wrapper">
        <div className="small-6 cell inner-wrapper">
          <h1>Email unsubscription</h1>
          {messageData && (
            <p
              className={classNames({
                'error-msg': messageData.status === 'error',
                'success-msg': messageData.status === 'success',
              })}
            >
              {messageData.message}
            </p>
          )}
          <p>
            {queryEmail
              ? 'Please remove me from your email list. I do not wish to receive email news or offers.'
              : 'Please enter your email address.'}
            <br />
            {!queryEmail && (
              <input ref={inputRef} className="form-control " id="email" name="email" placeholder="Email" type="text" />
            )}
          </p>
          <button type="button" className="button" onClick={() => handleClick()}>
            {!loading && 'REMOVE'}
            {loading && <img alt="loading" src={loaderLight} />}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Unsubscribe
