import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Unsubscribe from '@components/email/unsubscribe'
import Layout from '../components/layout'

function UnsubscribePage({ props }) {
  return (
    <Layout {...props} cartPage>
      <Helmet title="Unsubscribe - Rooms To Go" />
      <Unsubscribe />
    </Layout>
  )
}

UnsubscribePage.propTypes = {
  props: PropTypes.any,
}

export default UnsubscribePage
